.tn-detail-page-header, .tn-prod-season-header {
    padding: 0;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    .tn-detail-title, .tn-prod-season-header__title {
        font-size: 250%;
        padding: 0;
    }
    .tn-detail-image {
        display: none;
    }
}
.tn-detail {
    order: 1;
    .tn-detail-selected-date {
        font-size: 160%;
        display: block;
        float: none;
    }
    .tn-detail-performance-title { // repeated, hide
        display: none;
    }
}
.tn-detail-date-list {
    order: 2;
}

.tn-events-detail-page {
    //
    // Move additional events container below select your seat
    //
    .tn-event-detail {
        @media screen and (min-width: $screen-sm) {
            flex-wrap: wrap;
            .tn-event-detail__additional-events-container {
                order: 3;
                flex-basis: 100%;
                margin: 24px 0;
                .tn-additional-events {
                    .input-group {
                        display: flex;
                        max-width: 400px;
                    }
                }
            }
        }
        .tn-event-detail__main-container {
            margin-bottom: 0;
        }
    }
    .tn-prod-season-header__title {

    }
}


//
// Additional Events Panel
.tn-additional-events__btn-submit {
    padding: $spacer-xs $spacer-s;
}

.tn-additional-events__continue-shopping-link {
    @include c-cta;
    display: inline-block;
}


.max-width {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.page-wrap {
    background-color: $page-background;
}

main:not(.page-wrap) {
    margin-top: $spacer-m;
    @include mq($from: tablet) {
        margin-top: $spacer-l;
    }
}

.content-wrap {
    background: $white;
    padding-bottom: 50px;
    min-height: 90vh;
}

.o-wrapper {
    padding-right: $spacer-s;
    padding-left:  $spacer-s;
    margin-right: auto;
    margin-left:  auto;
    max-width: 1480px;

    @include mq($from: tablet) {
      padding-right: $spacer-m;
      padding-left:  $spacer-m;
    }
}

//
// Tab items
//
.tn-events-listing-page {
    .tn-event-listing-mode-tab-nav__list-item {
        color: $text-color;
        @include ff-sans;
    }
}

//
// Datepicker
//

.tn-events-list-view__datepicker-container, .tn-btn-datepicker__btn-container {
    .tn-btn-datepicker__icon-container {
        color: $white;
    }
}

//
// Calendar View
//

.tn-events-calendar-view {
    .tn-events-calendar__table th {
        @include ff-sans;
        text-align: center;
        font-size: 90%;
    }
    .tn-events-calendar__list-day-heading {
        @include ff-sans;  
    }
}

//
// List View
//

.tn-prod-list-item__perf-anchor {
    .btn-primary {
        padding: $spacer-xs $spacer-s;
    }
}
///* ========================================================================
//   #FONT FILES
//   ======================================================================== */

// Add your font-face links to fonts here

@font-face {
    font-family: 'Lyon Text';
    src: url(../fonts/LyonText-Regular-Web.woff2) format('woff2'),
            url(../fonts/LyonText-Regular-Web.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lyon Text';
    src: url(../fonts/LyonText-Regular-Web.woff2) format('woff2'),
            url(../fonts/LyonText-Regular-Web.woff) format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lyon Text';
    src: url(../fonts/LyonText-Bold-Web.woff2) format('woff2'),
            url(../fonts/LyonText-Bold-Web.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lyon Text';
    src: url(../fonts/LyonText-BoldItalic-Web.woff2) format('woff2'),
            url(../fonts/LyonText-BoldItalic-Web.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'ABC Marfa';
    src: url(../fonts/ABCMarfa-Regular.woff2) format('woff2'),
            url(../fonts/ABCMarfa-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ABC Marfa';
    src: url(../fonts/ABCMarfa-Bold.woff2) format('woff2'),
            url(../fonts/ABCMarfa-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'ABC Marfa';
    src: url(../fonts/ABCMarfa-BoldItalic.woff2) format('woff2'),
            url(../fonts/ABCMarfa-BoldItalic.woff) format('woff');
    font-weight: 700;
    font-style: italic;
}

@mixin ff-sans {
    font-family: 'ABC Marfa'
}

@mixin ff-serif {
    font-family: 'Lyon Text'
}

/* Font awesome fonts
   ========================================================================== */

@mixin fa-reg {
    font-family: 'Font Awesome 5 Pro';
    @include font-reg;
}

@mixin fa-light {
    font-family: 'Font Awesome 5 Pro';
    @include font-light;
}

@mixin fa-solid {
    font-family: 'Font Awesome 5 Pro';
    @include font-super-heavy;
}

@mixin fa-brands {
    font-family: 'Font Awesome 5 Brands';
}

@mixin fa-dutone {
    font-family: 'Font Awesome 5 Dutone';
}

// Weights

@mixin font-thin {
    font-weight: 100;
}
@mixin font-light {
    font-weight: 300;
}
@mixin font-reg {
    font-weight: 400;
}
@mixin font-med {
    font-weight: 500;
}
@mixin font-semi-bold {
    font-weight: 600;
}
@mixin font-bold {
    font-weight: 700;
}
@mixin font-heavy {
    font-weight: 800;
}
@mixin font-super-heavy {
    font-weight: 800;
}
@mixin font-italic {
    font-style: italic;
}
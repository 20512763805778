
.tn-event-detail__display-time {

}

.tn-event-detail__purchase-instructions a {
    @include c-cta;
}


//
// SYOS Cart Controls
//
.tn-syos-screen-container__button-list-container {
    .tn-syos-screen-button:not(:disabled) {
        background-color: $green;
        color: $black;
        border-color: #c8c8c8;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        &:hover:active,
        &:focus:active {
            background-color: lighten($green, 10%);
            border-color: lighten(#c8c8c8, 12%);
        }
    }
}

.tn-syos-mobile-cart-controls {
    .tn-syos-mobile-cart-controls__summary {
        padding: 5px 15px;
    }
    .tn-syos__btn-add-to-cart {
        background-color: $btn-primary-bg;
        color: $btn-primary-color;
        font-weight: $btn-font-weight;
        border-color: #aaa;
        text-transform: none;
        @include font-semi-bold;
        font-size: 100%;
        white-space: pre-wrap;
        &:not(:disabled) {
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active,
            &:hover:active,
            &:focus:active {
                background-color: lighten($btn-primary-bg, 10%);
                border-color: lighten(#aaa, 12%);
            }
        }
    }
}

//
// Modal
//

.tn-syos {
    .tn-syos--cart-details-visible .tn-syos-cart, .tn-syos-mobile-overlay__container, .tn-syos-price-type-selector {
        z-index: 1000;
    }
}

// Move z-index of viewing sites above header/footer
body.tn-syos--viewing-seats .tn-syos {
    z-index: 1000;
}

.tn-syos .modal {
    top: $header-height-mobile + 20px;
    @media (min-width: $header-full-menu) {
        top: $header-height-desktop + 20px;
    }
}

.tn-syos-mobile-popover .tn-syos-mobile-overlay__scroll-content-wrapper {
    margin-top: $header-height-mobile + 20px;
    @media (min-width: $header-full-menu) {
        margin-top: $header-height-desktop + 20px;
    }
}

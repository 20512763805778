/*  ==========================================================================
    # SITE FOOTER
    ========================================================================== */

.c-global-footer {
    padding: $spacer-m 0;
    background-color: $white;
    position: relative;
    z-index: 100;

    @include mq($from: tablet) {
        padding: $spacer-l 0;
    }

    &__inner {
        position: relative;
        border-top: 1px solid $black;
        padding-top: $spacer-m;

        @include mq($from: tablet) {
            padding-top: $spacer-l;
        }

        &::before {
            background: url('../img/TH-mark.svg');
            background-repeat: no-repeat;
            background-position: center;
            content: '';
            position: absolute;
            top: -25px;
            right: 0;
            @include vendor(transform, translateX(-50%));
            height: 50px;
            width: 22px;
        }
    }

    &__address {
        strong {
            display: block;
        }

        & + & {
            margin-top: $spacer-s;
        }
    }

    &__rhs {
        margin-top: $spacer-m;

        @include mq($from: tablet) {
            text-align: right;
            position: absolute;
            top: $spacer-m;
            right: $spacer-l;
        }
    }

    &__substrakt {
        margin-top: $spacer-m;

        @include mq($from: tablet) {
            margin-top: $spacer-l;
        }

        a {
            text-decoration: underline;
        }
    }
} 

.c-footer-menu, .c-social-media-links {
    margin: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: inline-block;

    }
}

.c-footer-menu {
    margin-top: $spacer-m;
    
    li {
        margin-right: $spacer-xs;
    }

    a {
        text-decoration: underline;;
    }
}

.c-btn--mail-signup {
    .fas {
        margin-right: 0.5em;
        font-size: 90%;
    }
}

.c-social-media-links {
    font-size: 140%;
    margin-top: $spacer-m;

    li {
        margin: 0 $spacer-xs;
    }
}
.tn-cart-details-page, .tn-payment-page {
    // Alert Upsell
    .tn-heading-info {
        button {
            // @include btn-link;
            @include c-cta;
        }
    }
    //
    // Cart Items
    //
    .tn-cart__section-items {
        .tn-performance-title {
            color: $headings-color;
            font-weight: $headings-font-weight;
        }
    }
    //
    // Cart Totals
    //
    .tn-cart-totals__line-item {
        color: $gray-darker;
    }
    .tn-cart-totals__line-item--primary {
        color: $text-color;
    }
    .tn-cart-totals__value--total {
        font-weight: $headings-font-weight;
    }
    //
    // Cart Buttons
    //
    .tn-cart-buttons {
        margin-top: 30px;
        .tn-cart-buttons__secondary-action {
            @include c-cta;
            font-size: 120%;
        }
    }
    .tn-edit-billing-link, .tn-edit-delivery-link {
        @include c-cta;
        display: inline-block;
    }
    .tn-payment-submit-component {
        margin-top: $padding-base-vertical;
        display: flex;
        width: 100%;
        flex-direction: column;
        max-width: 100%;
        padding-right: 0;
        button.btn-primary {
            min-width: min(100%, 280px);
            align-self: flex-end;
            @include mq($until: tablet) {
                align-self: stretch;
                flex-grow: 1;
                max-width: none;
            }
        }
    }
}

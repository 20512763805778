/*  ==========================================================================
    # SITE HEADER
    ========================================================================== */

// Variables

$header-full-menu: 1200px;
$header-height-mobile: 65px;
$header-height-desktop: 90px;
$eyebrow-height: 35px;


//
// Eyebrow styles
//

.c-site-eyebrow {
    height: $eyebrow-height;
    position: fixed;
    width: 100%;
    @include ff-sans;
    background-color: $white;
    z-index: 499;

    &__rhs {
        position: absolute;
        right: 0;
        top: 0;
    }

    .c-search-form {
        font-size: 0.7em;
        margin-left: 10px;
        top: 5px;
        width: 200px;
        display: none;

        @include mq($from: tablet) {
            display: block;
        }
    }
}

.c-search-trigger {
    border: 0;
    background: transparent;
    padding: 7px 20px 5px 10px;
    cursor: pointer;
    position: absolute;

    &:hover {
        background: transparent;
        color: inherit;
    }

    @include mq($from: tablet) {
        display: none;
    }
}

.c-account-links {
    font-size: 0;

    &__item {
        padding: 10px;
        text-transform: uppercase;
        font-size: 0.7rem;
        display: inline-block;

        .fas {
            margin-right: 5px;
        }

        &--cart {
            padding-right: 20px;
        }

        &--calendar {
            border-left: 1px solid $black;
            padding-left: 20px;
            padding-right: 30px;
            display: none;

            @include mq($from: tablet) {
                display: inline-block
            }
        }

        [data-whatintent="mouse"] & {
            &:hover {
                background-color: black(5);
            }
        }
    }
}

//
// Header styles
//


.c-global-header {
    background-color: $green;
    color: $black;
    @include ff-sans;
    position: relative;
    padding: $spacer-s;
    height: $header-height-mobile;
    position: fixed;
    width: 100%;
    z-index: 500;
    @include vendor(transform, translateY($eyebrow-height));
    @include transition(all 250ms ease-in-out 0ms);

    @include mq($from: $header-full-menu) {
        height: $header-height-desktop;
    }


    .state--header-stuck & {
        @include vendor(transform, translateY(0));
    }
}

.c-global-header {
    &__lhs, &__rhs {
        width: calc(50% - 110px); // 50% width - half logo width + padding
        text-align: center;
        position: absolute;
        @include v-center;
        display: none;

        @include mq($from: $header-full-menu) {
            display: block;
        }
    }

    &__lhs {
        left: 0;
        text-align: right;
        padding-right: $spacer-l;
    }

    &__rhs {
        right: 0;
        text-align: left;
        padding-left: $spacer-l;
    }
}

.c-global-header {
    &__logo {

        &--mobile {
            display: block;
            width: 150px;

            @include mq($from: $header-full-menu) {
                display: none;
            }
        }

        &--desktop {
            margin: 5px auto 0;
            width: 200px;
            display: none;

            @include mq($from: $header-full-menu) {
                display: block;
            }
        }

        path {
            fill: $black;
        }
    }
}

//
// Navigation styles
//


.c-header-nav {
    font-size: 0.8em;

    @include mq($from: 1440px) {
        font-size: 0.9em;
    }

    &__item {
        text-transform: uppercase;
        margin: 0 $spacer-s;
        position: relative;
        padding: 3px 6px;

        &::before {
            background-color: rgba($white, 0.2);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            @include vendor(transform, scaleX(0));
            @include transition(transform 350ms ease);
        }

        [data-whatintent="mouse"] & {
            &:hover::before {
                @include vendor(transform, scaleX(1))
            }
        }

        @include mq($from: 1440px) {
            margin: 0 $spacer-m;
        }
    }

}


button[type="button"].c-menu-trigger {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    border: 0;
    color: inherit;
    @include font-semi-bold;
    text-align: center;
    background: transparent;
    position: absolute;
    top: 20px;
    right: 10px;
    padding-left: 60px;

    &:hover {
        background: transparent;
    }

    @include mq($from: $header-full-menu) {
        display: none;
    }
}

.c-menu-trigger__label {
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 0;
    @include font-reg;

    &--close {
        display: none;
    }
}

.c-mobile-navigation {
    // Hide the menu
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    @include transition(none);
    text-transform: uppercase;
    font-size: 1.2em;
    margin-top: 30px;
    padding-bottom: 120px;

    &__item {
        display: block;
        padding: $spacer-s 0;
        position: relative;

        &::after {
            font-family: 'Font Awesome 5 Pro';
            content: '\f054';
            position: absolute;
            right: $spacer-s;
            top: 50%;
            @include vendor(transform, translateY(-50%));
        }

        & + & {
            border-top: 1px solid rgba($white, 0.3);
        }

    }

    @include mq($from: $header-full-menu) {
        display: none;
    }

}

// Nav Open Styles

html.state--nav-open,  html.state--nav-open body {
    overflow: hidden;
    height: 100%;

}

.state--nav-open {
    .c-global-header {
        height: 100%;

        @include mq($from: $header-full-menu) {
            height: $header-height-desktop;
        }
    }
    .c-mobile-navigation {
        height: 100%;
        opacity: 1;
        max-height: 500px;
        visibility: visible;
        @include transition(opacity 500ms ease-out 250ms);
        overflow-y: auto;
    }

    .c-menu-trigger {
        &__label {
            &--menu {
                display: none;
            }

            &--close {
                display: block;
            }
        }
    }
}

//
// Wrapper styles
//

main.page-wrap {
    padding-top: $header-height-mobile + $eyebrow-height;

    @include mq($from: $header-full-menu) {
        padding-top: $header-height-desktop + $eyebrow-height
    }
}

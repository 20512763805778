// Animated Icons
// --------------------------


.#{$fa-css-prefix}-spin {
  animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
}

.#{$fa-css-prefix}-pulse {
  animation: fa-spin 1s infinite steps(8);
  -webkit-animation: fa-spin 1s infinite steps(8);
}

@keyframes spin {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@-webkit-keyframes spin {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}

@-webkit-keyframes fa-spin {
   from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

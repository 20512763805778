.tn-subnav-component {
	@include ff-sans;
}

.subnav-icon, .subnav-text, .tn-subnav-component .tn-cart-link .tn-timer, .tn-subnav-component .tn-icon, .tn-subnav-component .tn-login-link.tn-logged-in .tn-logged-in-email, .tn-subnav-component .tn-login-link.tn-logged-in .tn-logged-in-text, .tn-subnav-component .tn-text {
	// Override font increase at tablet
	font-size: 1em !important;
}

//
// Promo Code
//
#tn-apply-promo {
    input {
        transition: all 0.3s;
        background-color: transparent;
        border-radius: $border-radius-small 0 0 $border-radius-small;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding-left: 6px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:focus {
            @include tab-focus;
        }
    }
}

#tn-apply-promo input, #tn-subnav-promo-button {
    height: 40px;
    @media (min-width: $screen-md-max) {
        height: 35px;
    }
}

#tn-subnav-promo-button {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    transform: translateY(2px);
    box-shadow: none;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    font-size: 0;
    line-height: 1.5;
    padding: 5px 10px;
    transition: $global-transition;
    &:after {
        content: "\E080";
        font-family: "Glyphicons Halflings";
        font-size: 16px;
    }
}
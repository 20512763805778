/*  Call to action
    ========================================================================== */

@mixin c-cta {
    display: inline-block;
    cursor: pointer;
    position: relative;
    @include link-underline(transparent, currentColor, currentColor); // Sets a responsive underline
    padding: 5px 0;
    padding-right: 1.2em;
    @include font-bold;
    font-size: 110%;

    &::after {
        @include fa-reg;
        content: '\f061';
        font-size: 80%;
        @include font-reg;
        position: absolute;
        right: 0;
        @include v-center;
        display: inline-block;
        color: currentColor;
    }

    // Sets a spacer on call to actions to display them as a block item
    p + & {
        &::before {
            display: block;
            margin-top: 0.4rem;
            content: '';

            @include mq($from: tablet) {
                margin-top: 0.8rem;
            }
        }
    }

    // Reduces margin with two CTAs stacked on top of each other
    & + & {
        &::before {
            margin-top: 0.2rem;

            @include mq($from: tablet) {
                margin-top: 0.4rem;
            }
        }
    }
}

.c-cta {
    @include c-cta;
}


/* Accessibility Skip link button
   ========================================================================== */

.c-btn--skip-link {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    margin: 0 auto;
    position: absolute;
    z-index: 2000;
    left: 0;
    right: 0;
    top: 1em;
    width: 20em;
    opacity: 1;
}

.c-btn--skip-link:not(:focus):not(:hover) {
    -webkit-transform: translateY(-4em);
    -ms-transform: translateY(-4em);
    transform: translateY(-4em);
    opacity: 0;
}
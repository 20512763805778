#tn-account-login-forgot-login-link {
	@include c-cta;
}

//
// Even out grouped buttons
.tn-account-interests-page, .tn-account-update-page {
    .tn-btn-grouped {
        margin-top: 1em;
    }
}
